import "./Loader.scss";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../../../assets/logo.svg";

interface IProps {
  type: "brand";
}

const Loader = (props: IProps) => {
  const { type } = props;
  return (
    <div className="loader">
      {(() => {
        switch (type) {
          case "brand":
            return (
              <div className="loader__brand">
                <img className="loader__brandLogo" alt="logo" src={logo} />
                <CircularProgress
                  color="primary"
                  thickness={5}
                  size={32}
                  className="loader__brandCircularProgress"
                />
              </div>
            );
        }
      })()}
    </div>
  );
};

export default Loader;
