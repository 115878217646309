import { UserPermissions, UserRoles } from "./../models/enum";
import firebase from "firebase";
import _, { includes } from "lodash";
import { AxiosApi } from "../common/helpers/axios";
import GetUser from "../features/users/api/GetUser";

const signInAnonymously = async () => {
  try {
    return await firebase.auth().signInAnonymously();
  } catch (error) {
    console.error("TCL: signInAnonymously -> error", error);
  }
};

// const setAccessToken = async (user?: User | null) => {
//   console.log("setAccessToken -> user");
//   if (!user) {
//     console.error("Firebase auth failed to initialize");
//     return;
//   }
//   const token: string = await user.getIdToken();
//   console.log("Firebase token initialized");
//   localStorage.setItem("access_token", token);
// };

export const onAuthStateChanged = async (): Promise<firebase.User> => {
  return new Promise((resolve: any, reject: any) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      console.log("TCL: onAuthStateChanged -> user");
      const authUser = user ?? (await signInAnonymously())?.user;
      //setAccessToken(authUser);
      authUser ? resolve(authUser) : reject();
    });
  });
};

/**
 *
 * @param permissions List of current user permissions
 * @param permission Required permission
 */
export const isAuthorized = (
  permissions: UserPermissions[],
  permission?: UserPermissions
): boolean => {
  if (!permission) {
    return true;
  }
  return includes(permissions, permission);
};

export const logout = async (dispatch: any) => {
  await firebase.auth().signOut();
  await onAuthStateChanged();
  const request = GetUser(true);
  await dispatch(AxiosApi(request));
};
