import { LoginReducer } from "./../features/login/state/reducer";
import { combineReducers, Reducer } from "redux";
import { connectRouter } from "connected-react-router";
import AppState from "./AppState";
import { AppInfoReducer } from "../common/state/AppInfoReducer";
import { NavigationReducer } from "../features/navigation/state/state/reducer";
import { ClassroomReducer } from "../features/classrooms/state/reducer";

export const RootReducer = (history: any): Reducer<AppState> =>
  combineReducers<AppState>({
    router: connectRouter(history),
    appInfo: AppInfoReducer,
    login: LoginReducer,
    classroom: ClassroomReducer,
    navigation: NavigationReducer,
  });
