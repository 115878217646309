import { AnyAction, Reducer } from "redux";
import {
  NavigationReducerState,
  InitialNavigationReducerState,
} from "./NavigationReducerState";
import { NavigationActionTypes } from "./action";

export const NavigationReducer: Reducer<NavigationReducerState> = (
  state: NavigationReducerState = InitialNavigationReducerState,
  action: AnyAction
) => {
  switch (action.type) {
    case NavigationActionTypes.SetHeaderActions:
      return { ...state, headerActions: action.payload };
    case NavigationActionTypes.ClearHeaderActions:
      return { ...state, headerActions: [] };
    case NavigationActionTypes.SetHeaderTitle:
      return { ...state, headerTitle: action.payload };
    default:
      return state;
  }
};
