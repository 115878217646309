import { createAction } from "redux-actions";
import User from "../../../models/User";
export enum LoginActionTypes {
  GetUser = "@@UI/login/GetUser",
  GetUserOnSuccess = "@@UI/login/GetUserOnSuccess",
  GetUserOnFail = "@@UI/login/GetUserOnFail",
}

export const LoginActions = {
  GetUser: createAction(LoginActionTypes.GetUser),
  GetUserOnSuccess: createAction(LoginActionTypes.GetUserOnSuccess, (user: User) => user),
  GetUserOnFail: createAction(LoginActionTypes.GetUserOnFail),
};
