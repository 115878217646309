import { isBoolean, isNumber, isString } from "lodash";
import SystemConfig from "../../SystemConfig";
import firebase from "firebase";

export const initRemoteConfig = async (intialSystemConfig: SystemConfig) => {
  const remoteConfig = firebase.remoteConfig();
  remoteConfig.defaultConfig = { ...initRemoteConfig };
  const fetchedRemotely = await remoteConfig.fetchAndActivate();

  if (fetchedRemotely) {
    console.log("Configs were retrieved from the backend and activated.");
  } else {
    console.log(
      "No configs were fetched from the backend, and the local configs were already activated"
    );
  }
};

export function getSystemConfigValue(key: keyof SystemConfig) {
  const value = firebase.remoteConfig().getValue(key);
  if (isString(SystemConfig[key])) {
    return value.asString();
  } else if (isNumber(SystemConfig[key])) {
    return value.asNumber();
  } else if (isBoolean(SystemConfig[key])) {
    return value.asBoolean();
  } else {
    return value.getSource();
  }
}
