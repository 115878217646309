export enum RoutePath {
  Login = "/",
  Page = "/page/:name",
  UsersPage = "/page/users",
  VideosPage = "/page/videos",
  OrganizationsPage = "/page/organizations",
  DocumentsPage = "/page/documents",
  DocumentEditPage = "/page/documents/edit",
  ClassroomPage = "/page/classrooms",
  ClassroomLivePage = "/page/classrooms/:id/live",
  ClassroomDetailsPage = "/page/classrooms/:id/details",
  APICatalogue = "/api-catalogue",
  OrganizationSettingsPage = "/page/organization/settings",
}

export interface IPathWithId {
  id: string;
}

export const IdPath = ":id";
