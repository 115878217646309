import React from "react";
import AppState from "../store/AppState";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Loader from "../common/components/loader/Loader";

interface IProps {
  appReady: boolean;
}

const PrivateRoute: React.FunctionComponent<IProps> = (props) => {
  const { children, appReady } = props;
  if (!appReady) {
    return <div className="splash-screen"><Loader type="brand" /></div>;
  }
  return <>{children}</>;
};

const mapStateToProps = (state: AppState) => ({
  appReady: state.appInfo.appReady,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
