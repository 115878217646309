import { IRequest } from "../../../common/helpers/axios";
import { LoginActionTypes } from "../../login/state/action";

const GetUser = (stateUpdate: boolean = true): IRequest => {
  return {
    url: "/api/v1/User",
    actionType: stateUpdate ? LoginActionTypes.GetUser : undefined,
  };
};
export default GetUser;
