import thunk from "redux-thunk";
import axiosMiddleware from "redux-axios-middleware";
import { createStore, applyMiddleware } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { logger } from "redux-logger";
import localforage from "localforage";

import { RootReducer } from "./RootReducer";
import { client } from "../common/helpers/axios";
import { axiosMiddlewareConfig } from "../common/config/axiosMiddlewareConfig";
import Initialize from "./AppInit";

const persistConfig = {
  key: "root",
  storage: localforage,
  whitelist: [],
};

export const history = createBrowserHistory();

const configureStore = () => {
  const persistedReducer = persistReducer(persistConfig, RootReducer(history));
  const appStore = createStore(
    persistedReducer,
    applyMiddleware(
      thunk,
      //logger,
      routerMiddleware(history),
      axiosMiddleware(client, axiosMiddlewareConfig)
    )
  );
  Initialize(appStore.dispatch);
  return appStore;
};

export const store = configureStore();
export const persistor = persistStore(store);
