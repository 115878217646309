import "./App.scss";
import React, { Suspense } from "react";
import { Redirect, Route, BrowserRouter, Switch } from "react-router-dom";
import { store, persistor, history } from "./store/AppStore";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import SystemConfig from "./SystemConfig";
import DashboardRoute from "./layouts/DashboardRoute";
import PrivateRoute from "./layouts/PrivateRoute";
import { RoutePath } from "./models/RoutePath";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "./common/components/loader/Loader";
import NotificationProvider from "./layouts/NotificationProvider";
import { SnackbarProvider } from "notistack";
import { CustomThemeOptions } from "./config/palette";

const Login = React.lazy(() => import("./features/login/components/Login"));
const Videos = React.lazy(() => import("./features/videos/components/Videos"));
const Users = React.lazy(() => import("./features/users/components/Users"));
const Organizations = React.lazy(
  () => import("./features/organizations/components/Organizations")
);

const Classrooms = React.lazy(
  () => import("./features/classrooms/components/Classrooms")
);

const LiveClass = React.lazy(
  () => import("./features/classrooms/components/liveClass/LiveClass")
);

const ClassroomDetails = React.lazy(
  () =>
    import("./features/classrooms/components/classroomDetails/ClassroomDetails")
);

const OrganizationSettings = React.lazy(
  () =>
    import("./features/organizations/components/organizationSettings/Settings")
);

const Documents = React.lazy(
  () => import("./features/documents/components/Documents")
);

const BookForm = React.lazy(
  () => import("./features/documents/components/BookForm")
);

const theme = createMuiTheme(CustomThemeOptions);

function App() {
  return (
    <Provider store={store}>
      <PersistGate
        loading={
          <div className="splash-screen">
            <Loader type="brand" />
          </div>
        }
        persistor={persistor}
      >
        <BrowserRouter>
          <ConnectedRouter history={history}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={SystemConfig.snackbarTimeout}
              preventDuplicate={true}
            >
              <ThemeProvider theme={theme}>
                <NotificationProvider>
                  <Suspense
                    fallback={
                      <div className="splash-screen">
                        <Loader type="brand" />
                      </div>
                    }
                  >
                    <Switch>
                      <PrivateRoute>
                        <Route path={RoutePath.Login} component={Login} exact />
                        <DashboardRoute>
                          <Route
                            path={RoutePath.UsersPage}
                            component={Users}
                            exact
                          />
                          <Route
                            path={RoutePath.VideosPage}
                            component={Videos}
                            exact
                          />
                          <Route
                            path={RoutePath.DocumentsPage}
                            component={Documents}
                            exact
                          />
                          <Route
                            path={RoutePath.DocumentEditPage}
                            component={BookForm}
                            exact
                          />
                          <Route
                            path={RoutePath.OrganizationsPage}
                            component={Organizations}
                            exact
                          />
                          <Route
                            path={RoutePath.ClassroomPage}
                            component={Classrooms}
                            exact
                          />
                          <Route
                            path={RoutePath.ClassroomLivePage}
                            component={LiveClass}
                            exact
                          />
                          <Route
                            path={RoutePath.ClassroomDetailsPage}
                            component={ClassroomDetails}
                            exact
                          />
                          <Route
                            path={RoutePath.OrganizationSettingsPage}
                            component={OrganizationSettings}
                            exact
                          />
                        </DashboardRoute>
                      </PrivateRoute>
                    </Switch>
                  </Suspense>
                </NotificationProvider>
              </ThemeProvider>
            </SnackbarProvider>
          </ConnectedRouter>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
