import { AnyAction, Reducer } from "redux";
import { ClassroomActionTypes } from "./action";
import {
  ClassroomReducerState,
  InitialClassroomReducerState,
} from "./ClassroomReducerState";

export const ClassroomReducer: Reducer<ClassroomReducerState> = (
  state: ClassroomReducerState = InitialClassroomReducerState,
  action: AnyAction
) => {
  switch (action.type) {
    case ClassroomActionTypes.SetCurrentClassroom:
      return { ...state, currentClassroom: action.payload };
    default:
      return state;
  }
};
