import React, { useEffect } from "react";
import AppState from "../store/AppState";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import User from "../models/User";
import { isAuthorized } from "../helpers/auth";
import { UserPermissions } from "../models/enum";
import { Redirect } from "react-router";
import { RoutePath } from "../models/RoutePath";
import { initFCM } from "../helpers/notification";
import SystemConfig from "../SystemConfig";

const Navigation = React.lazy(
  () => import("../features/navigation/components/Navigation")
);

interface IProps {
  user: User;
}

const DashboardRoute: React.FunctionComponent<IProps> = (props) => {
  const { children, user } = props;
  console.log("user", user);

  if (isAuthorized(user.permissions, SystemConfig.minimunPermissionRequired)) {
    return <Navigation>{children}</Navigation>;
  } else if (isAuthorized(user.permissions, UserPermissions.ReadUserSelf)) {
    return <Redirect to={RoutePath.Login} />;
  } else {
    return null;
  }
};

const mapStateToProps = (state: AppState) => ({
  user: state.login.user,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardRoute);
