import { createAction } from "redux-actions";
import { IClassroom } from "../../../models/Classroom";

export enum ClassroomActionTypes {
  SetCurrentClassroom = "@@UI/classrooms/SetCurrentClassroom",
}

export const ClassroomActions = {
  SetCurrentClassroom: createAction(
    ClassroomActionTypes.SetCurrentClassroom,
    (classroom: IClassroom | undefined) => classroom
  ),
};
