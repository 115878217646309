import firebase from "firebase";
import CreatePushToken from "../store/api/CreatePushToken";
import { AxiosApi } from "../common/helpers/axios";

export const initFCM = async (): Promise<string> => {
  const messaging = firebase.messaging();
  await messaging.requestPermission();
  const token = await messaging.getToken();
  console.log("token: ", token);
  return token;
};

/**
 * Update fcm token to server
 * @param dispatch Redux disptach object
 */
export const createPushToken = async (dispatch: any) => {
  try {
    const token = await initFCM();
    const request = CreatePushToken(token);
    return dispatch(AxiosApi(request));
  } catch (err) {
    console.error(err);
  }
};
