import config from "./config.json";
import { UserPermissions } from "./models/enum";
import { OperatingSystem, getOperatingSystem } from "./common/helpers/platform";
import { includes } from "lodash";
import { IPlayback } from "./common/models/cloudflare/CloudflareVideoDetails";

interface SystemConfig {
  snackbarTimeout: number;
  browserTitle: string;
  currency: string;
  isMobile: boolean;
  emptyFieldText: string;
  swaggerUrl: string;
  isEmailAuth: boolean;
  jitsiDomain: string;
  streamingProtocol: keyof IPlayback;
  /**
   * Use direct creator upload
   */
  isStreamDirectUpload: boolean;
  /**
   * Lowest user role in the hierarchy which can access to portal
   */
  minimunPermissionRequired: UserPermissions;
}

const SystemConfig: SystemConfig = {
  snackbarTimeout: 3000,
  browserTitle: "Classmate Admin",
  currency: "₹",
  isMobile: includes(
    [
      OperatingSystem.Andorid,
      OperatingSystem.WindowsPhone,
      OperatingSystem.iOS,
    ],
    getOperatingSystem()
  ),
  emptyFieldText: "Not Available",
  swaggerUrl: config.endpoint,
  minimunPermissionRequired: UserPermissions.ReadOrganizationSelf,
  isEmailAuth: true,
  streamingProtocol: "hls",
  jitsiDomain: "meet.jit.si",
  isStreamDirectUpload: true,
};

export default SystemConfig;
