import { AppInfoActions } from "../common/state/AppInfoAction";
import { AxiosApi, IResponse } from "../common/helpers/axios";
import firebase from "firebase";
import config from "../config.json";
import { Dispatch } from "redux";
import { onAuthStateChanged } from "../helpers/auth";
import GetConnectionPing from "./api/GetConnectionPing";
import SystemConfig from "../SystemConfig";
import GetUser from "../features/users/api/GetUser";
import { initRemoteConfig } from "../common/helpers/remoteConfig";

const getUser = async (dispatch: Dispatch) => {
  const request = GetUser();
  return dispatch(AxiosApi(request));
};

const pingServerConnection = async (dispatch: Dispatch) => {
  const request = GetConnectionPing();
  return dispatch(AxiosApi(request));
};

const updateSystemConfig = async (dispatch: any) => {
  initRemoteConfig(SystemConfig);
};

const Initialize = async (dispatch: Dispatch) => {
  firebase.initializeApp(config.firebase);
  await onAuthStateChanged();
  await pingServerConnection(dispatch);
  console.log("Server connection ping success");
  // Blocking tasks should be added to promise array below
  await Promise.all([getUser(dispatch)]);
  // Non blocking tasks should be added to promise array below
  Promise.all([updateSystemConfig(dispatch)]);
  await dispatch(AppInfoActions.AppReady());
};

export default Initialize;
