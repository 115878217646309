import { createAction } from "redux-actions";
import HeaderAction from "../../../../models/HeaderAction";

export enum NavigationActionTypes {
  SetHeaderActions = "@@UI/navigation/SetHeaderActions",
  ClearHeaderActions = "@@UI/navigation/ClearHeaderActions",
  SetHeaderTitle = "@@UI/navigation/SetHeaderTitle",
}

export const NavigationActions = {
  SetHeaderActions: createAction(
    NavigationActionTypes.SetHeaderActions,
    (headerActions: HeaderAction[]) => headerActions
  ),
  ClearHeaderActions: createAction(NavigationActionTypes.ClearHeaderActions),
  SetHeaderTitle: createAction(
    NavigationActionTypes.SetHeaderTitle,
    (title: string | undefined) => title
  ),
};
