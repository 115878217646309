import { getDeviceId } from "../../common/helpers/platform";
import { IRequest } from "../../common/helpers/axios";
import { PushTokenEdit } from "../../common/models/PushToken";
import { RequestTypes } from "../../common/models/enum";

export default function CreatePushToken(token: string): IRequest {
  const data: PushTokenEdit = {
    deviceName: navigator.userAgent,
    platform: navigator.platform,
    token,
  };
  return {
    url: "/api/v1/PushToken",
    method: RequestTypes.Post,
    data,
  };
}
