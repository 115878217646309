import HeaderAction from "../../../../models/HeaderAction";

export interface NavigationReducerState {
  headerActions: HeaderAction[];
  headerTitle?: string;
}

export const InitialNavigationReducerState: NavigationReducerState = {
  headerActions: [],
};
