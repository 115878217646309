import React, { FunctionComponent, useEffect } from "react";
import AppState from "../store/AppState";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import ToastMessage from "../common/models/ToastMessage";
import firebase from "firebase";
import PushNotification from "../common/models/PushNotification";
import { createPushToken } from "../helpers/notification";
import User from "../models/User";

interface IProps {
  user: User;
  appReady: boolean;
  lastApiError?: ToastMessage;
  updateFCMToken(): Promise<any>;
}

const NotificationProvider: FunctionComponent<IProps> = (props) => {
  const { lastApiError, children, user, appReady, updateFCMToken } = props;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const unsubscribeMessaging = firebase.messaging.isSupported()
      ? firebase.messaging().onMessage((payload: PushNotification) => {
          enqueueSnackbar(
            `${payload.notification.title}: ${payload.notification.body}`,
            {
              variant: "info",
            }
          );
        })
      : undefined;

    return () => {
      unsubscribeMessaging && unsubscribeMessaging();
    };
  }, []);

  useEffect(() => {
    if (lastApiError) {
      enqueueSnackbar(lastApiError.message, { variant: "error" });
    }
  }, [lastApiError]);

  useEffect(() => {
    appReady && updateFCMToken();
  }, [user, appReady]);

  return <>{children}</>;
};

const mapStateToProps = (state: AppState) => ({
  lastApiError: state.appInfo.lastApiError,
  appReady: state.appInfo.appReady,
  user: state.login.user,
});
const mapDispatchToProps = (dispatch: any) => ({
  updateFCMToken: () => createPushToken(dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationProvider);
